import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/page-layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SiteMetaData = makeShortcode("SiteMetaData");
const Divider = makeShortcode("Divider");
const SourceList = makeShortcode("SourceList");
const SourceDays = makeShortcode("SourceDays");
const Box = makeShortcode("Box");
const Flex = makeShortcode("Flex");
const Text = makeShortcode("Text");
const SourceMonths = makeShortcode("SourceMonths");
const Heading = makeShortcode("Heading");
const SourceTags = makeShortcode("SourceTags");
const SourceWords = makeShortcode("SourceWords");
const Donut = makeShortcode("Donut");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1><a parentName="h1" {...{
        "href": "#-gatsby-theme-terminal"
      }}>{`gatsby-theme-terminal`}</a></h1>
    <p>{`gatsby-theme-terminal has some of it's own components, and below is how you use them.`}</p>
    <h2><a parentName="h2" {...{
        "href": "#site-meta-data"
      }}>{`SiteMetaData`}</a></h2>
    <p>{`The `}<inlineCode parentName="p">{`<SiteMetaData />`}</inlineCode>{` component returns all fields specified by `}<inlineCode parentName="p">{`siteMetadata`}</inlineCode>{` in `}<inlineCode parentName="p">{`gatsby-config.js`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<SiteMetaData>
  {(siteMetadata) => {
    const { name, description } = siteMetadata
    return (
      <ul>
        <li>{name}</li>
        <li>{description}</li>
      </ul>
    )
  }}
</SiteMetaData>
`}</code></pre>
    <SiteMetaData mdxType="SiteMetaData">
  {siteMetadata => {
        const {
          name,
          description
        } = siteMetadata;
        return <ul>
        <li>{name}</li>
        <li>{description}</li>
      </ul>;
      }}
    </SiteMetaData>
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#source-list"
      }}>{`SourceList`}</a></h2>
    <p>{`By default the source list returns all `}<inlineCode parentName="p">{`.mdx`}</inlineCode>{` found from the directories defined in `}<inlineCode parentName="p">{`gatsby-config.js`}</inlineCode>{`. You can also use
the `}<inlineCode parentName="p">{`filter`}</inlineCode>{` prop with this component eg: `}<inlineCode parentName="p">{`<SourceList filter='posts' />`}</inlineCode></p>
    <p><em parentName="p">{`NOTE:`}</em>{` the `}<inlineCode parentName="p">{`filter`}</inlineCode>{` source `}<em parentName="p">{`must`}</em>{` be one from your `}<inlineCode parentName="p">{`gatsby-config.js`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<SourceList>
  {(source) => (
    <ul>
      {source.map((edge, index) => {
        const {
          frontmatter: { title },
        } = edge.node
        return <li key={index}>{title}</li>
      })}
    </ul>
  )}
</SourceList>
`}</code></pre>
    <SourceList mdxType="SourceList">
  {source => <ul>
      {source.map((edge, index) => {
          const {
            frontmatter: {
              title
            }
          } = edge.node;
          return <li key={index}>{title}</li>;
        })}
    </ul>}
    </SourceList>
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#source-days"
      }}>{`SourceDays`}</a></h2>
    <p>{`By default source days returns an accumulated count and percent of all `}<inlineCode parentName="p">{`frontmatter`}</inlineCode>{` date fields grouped by year. You
can also use the `}<inlineCode parentName="p">{`filter`}</inlineCode>{` prop with this component eg: `}<inlineCode parentName="p">{`<SourceDays filter='posts' />`}</inlineCode></p>
    <p><em parentName="p">{`NOTE:`}</em>{` the `}<inlineCode parentName="p">{`filter`}</inlineCode>{` source `}<em parentName="p">{`must`}</em>{` be one from your `}<inlineCode parentName="p">{`gatsby-config.js`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<SourceDays>
  {(sourceDays) => {
    const currentYear = sourceDays[sourceDays.length - 1]
    return (
      <Box>
        {currentYear
          .sort((a, b) => a.number - b.number)
          .map((day, index) => {
            const { name, count, percent } = day
            return (
              <Flex
                key={index}
                sx={{
                  backgroundColor: 'surface',
                  flexDirection: 'column',
                  mb: 2,
                  position: 'relative',
                }}
              >
                <Box
                  sx={{
                    backgroundColor: 'primary',
                    height: '100%',
                    position: 'absolute',
                    width: \`\${percent}%\`,
                  }}
                />
                <Box
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Text sx={{ textTransform: 'capitalize', pl: 2 }}>{name}</Text>
                  <Text sx={{ pr: 2 }}>{\`x\${count}\`}</Text>
                </Box>
              </Flex>
            )
          })}
      </Box>
    )
  }}
</SourceDays>
`}</code></pre>
    <SourceDays mdxType="SourceDays">
  {sourceDays => {
        const currentYear = sourceDays[sourceDays.length - 1];
        return <Box mdxType="Box">
        {currentYear.sort((a, b) => a.number - b.number).map((day, index) => {
            const {
              name,
              count,
              percent
            } = day;
            return <Flex key={index} sx={{
              backgroundColor: 'surface',
              flexDirection: 'column',
              mb: 2,
              position: 'relative'
            }} mdxType="Flex">
                <Box sx={{
                backgroundColor: 'primary',
                height: '100%',
                position: 'absolute',
                width: `${percent}%`
              }} mdxType="Box" />
                <Box sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'space-between'
              }} mdxType="Box">
                  <Text sx={{
                  textTransform: 'capitalize',
                  pl: 2
                }} mdxType="Text">{name}</Text>
                  <Text sx={{
                  pr: 2
                }} mdxType="Text">{`x${count}`}</Text>
                </Box>
              </Flex>;
          })}
      </Box>;
      }}
    </SourceDays>
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#source-months"
      }}>{`SourceMonths`}</a></h2>
    <p>{`By default source months returns an accumulated count and percent of all `}<inlineCode parentName="p">{`frontmatter`}</inlineCode>{` date fields grouped by year. You
can also use the `}<inlineCode parentName="p">{`filter`}</inlineCode>{` prop with this component eg: `}<inlineCode parentName="p">{`<SourceMonths filter='posts' />`}</inlineCode></p>
    <p><em parentName="p">{`NOTE:`}</em>{` the `}<inlineCode parentName="p">{`filter`}</inlineCode>{` source `}<em parentName="p">{`must`}</em>{` be one from your `}<inlineCode parentName="p">{`gatsby-config.js`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<SourceMonths>
  {(sourceMonths) => {
    const currentYear = sourceMonths[sourceMonths.length - 1]
    return (
      <Box sx={{ backgroundColor: 'surface', p: 3 }}>
        <Heading variant="styles.h4">{currentYear[0].year}</Heading>
        <Box sx={{ display: 'flex', flex: '1 1 auto', height: 300 }}>
          <Flex sx={{ flexWrap: 'wrap', width: '100%' }}>
            {currentYear.map((month, index) => {
              const { initial, count, percent } = month
              return (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    pl: 1,
                    pr: 1,
                    width: \`\${100 / currentYear.length}%\`,
                  }}
                >
                  <Text
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    {\`x\${count}\`}
                  </Text>
                  <Box
                    sx={{
                      backgroundColor: 'primary',
                      height: \`\${percent}%\`,
                      p: 1,
                    }}
                  />
                  <Text
                    sx={{
                      textTransform: 'uppercase',
                      textAlign: 'center',
                    }}
                  >
                    {initial}
                  </Text>
                </Box>
              )
            })}
          </Flex>
        </Box>
      </Box>
    )
  }}
</SourceMonths>
`}</code></pre>
    <SourceMonths mdxType="SourceMonths">
  {sourceMonths => {
        const currentYear = sourceMonths[sourceMonths.length - 1];
        return <Box sx={{
          backgroundColor: 'surface',
          p: 3
        }} mdxType="Box">
        <Heading variant="styles.h4" mdxType="Heading">{currentYear[0].year}</Heading>
        <Box sx={{
            display: 'flex',
            flex: '1 1 auto',
            height: 300
          }} mdxType="Box">
          <Flex sx={{
              flexWrap: 'wrap',
              width: '100%'
            }} mdxType="Flex">
            {currentYear.map((month, index) => {
                const {
                  initial,
                  count,
                  percent
                } = month;
                return <Box key={index} sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  pl: 1,
                  pr: 1,
                  width: `${100 / currentYear.length}%`
                }} mdxType="Box">
                  <Text sx={{
                    textAlign: 'center'
                  }} mdxType="Text">
                    {`x${count}`}
                  </Text>
                  <Box sx={{
                    backgroundColor: 'primary',
                    height: `${percent}%`,
                    p: 1
                  }} mdxType="Box" />
                  <Text sx={{
                    textTransform: 'uppercase',
                    textAlign: 'center'
                  }} mdxType="Text">
                    {initial}
                  </Text>
                </Box>;
              })}
          </Flex>
        </Box>
      </Box>;
      }}
    </SourceMonths>
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#tag-list"
      }}>{`SourceTags`}</a></h2>
    <p>{`By default source tags returns all tags found in the `}<inlineCode parentName="p">{`.mdx`}</inlineCode>{` sourced from the directories defined in \`gatsby-config.js.`}</p>
    <p>{`You can also use the `}<inlineCode parentName="p">{`filter`}</inlineCode>{` prop with this component eg: `}<inlineCode parentName="p">{`<SourceTags filter='posts' />`}</inlineCode></p>
    <p><em parentName="p">{`NOTE:`}</em>{` the `}<inlineCode parentName="p">{`filter`}</inlineCode>{` source `}<em parentName="p">{`must`}</em>{` be one from your \`gatsby-config.js\`\``}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<SourceTags>
  {source => (
    <Flex
    sx={{flexDirection: 'column'}}
    >
      {
        source.map((tag, index) => {
          const {name, count, percent} = tag
          return (<Box  key={index}>
            {\`\${name}  x\${count}\`}
            <Box mb={2}  bg='muted' sx={{width: \`\${percent}%\`, height: 4}} />
          </Box>
        })
      }
    </Flex>
  )}
</SourceTags>
`}</code></pre>
    <SourceTags mdxType="SourceTags">
  {source => <Flex sx={{
        flexDirection: 'column'
      }} mdxType="Flex">
      {source.map((tag, index) => {
          const {
            name,
            count,
            percent
          } = tag;
          return <Box key={index} mdxType="Box">
            {`${name}  x${count}`}
            <Box mb={2} bg="muted" sx={{
              width: `${percent}%`,
              height: 4
            }} mdxType="Box"></Box>
          </Box>;
        })}
    </Flex>}
    </SourceTags>
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#embedded-images"
      }}>{`embeddedImages`}</a></h2>
    <p>{`Using a frontmatter field called `}<inlineCode parentName="p">{`embeddedImages`}</inlineCode>{` you can define a `}<em parentName="p">{`list`}</em>{` of locally sourced images to embed in the
`}<inlineCode parentName="p">{`.mdx`}</inlineCode>{` body.`}</p>
    <p><em parentName="p">{`NOTE:`}</em>{` this method won't work for `}<inlineCode parentName="p">{`.mdx`}</inlineCode>{` sourced from `}<inlineCode parentName="p">{`src/pages`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`---
title: Post 1
embeddedImages:
  - markus-spiske-FXFz-sW0uwo-unsplash.jpg
---
`}</code></pre>
    <Divider mdxType="Divider" />
    <p>{`You can then use the `}<inlineCode parentName="p">{`<EmbeddedImage />`}</inlineCode>{` component like this in your `}<inlineCode parentName="p">{`.mdx`}</inlineCode>{`.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`image1`}</inlineCode>{` object key refers to the position in the `}<inlineCode parentName="p">{`embeddedImages`}</inlineCode>{` list in frontmatter`}</p>
    <Divider mdxType="Divider" />
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi

<EmbeddedImage src={props.embedded.image1} />
`}</code></pre>
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#source-words"
      }}>{`SourceWords`}</a></h2>
    <p>{`By default source words returns a word count for all words found in the `}<inlineCode parentName="p">{`.mdx`}</inlineCode>{` sourced from the directories defined in
\`gatsby-config.js.`}</p>
    <p>{`You can also use the `}<inlineCode parentName="p">{`filter`}</inlineCode>{` prop with this component eg: `}<inlineCode parentName="p">{`<SourceTags filter='posts' />`}</inlineCode></p>
    <p><em parentName="p">{`NOTE:`}</em>{` the `}<inlineCode parentName="p">{`filter`}</inlineCode>{` source `}<em parentName="p">{`must`}</em>{` be one from your \`gatsby-config.js\`\``}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<SourceWords>
  {(source) => {
    const { wordCountTotal, wordCountAverage, wordCountHighest, wordCountLowest, timeToReadTotal, timeToReadAverage } =
      source
    return (
      <Box
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          mb: 3,
          px: 2,
          width: ['100%', '50%', '33.333333333%'],
        }}
      >
        <Flex
          sx={{
            alignItems: 'center',
            backgroundColor: 'surface',
            flex: '1 1 auto',
            flexDirection: 'column',
            justifyContent: 'center',
            p: 3,
            position: 'relative',
          }}
        >
          <Heading as="h4" variant="styles.h4">
            Average word count
          </Heading>
          <Donut sx={{ mx: 3, mb: 2 }} value={wordCountAverage / wordCountTotal} />
          <Box sx={{ position: 'absolute' }}>
            <Text
              sx={{
                textAlign: 'center',
                color: 'primary',
                fontSize: '22px',
                fontWeight: 'bold',
                lineHeight: '1',
              }}
            >
              {wordCountAverage}
            </Text>
            <Text sx={{ textAlign: 'center', color: 'primary', lineHeight: '1' }}>Words</Text>
          </Box>
          <Text sx={{ textAlign: 'center' }}>{\`Total words: \${wordCountTotal}\`}</Text>
        </Flex>
      </Box>
    )
  }}
</SourceWords>
`}</code></pre>
    <SourceWords mdxType="SourceWords">
  {source => {
        const {
          wordCountTotal,
          wordCountAverage,
          wordCountHighest,
          wordCountLowest,
          timeToReadTotal,
          timeToReadAverage
        } = source;
        return <Flex sx={{
          flexWrap: 'wrap',
          mx: theme => `-${theme.space[2]}px`
        }} mdxType="Flex">
        <Box sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            mb: 3,
            px: 2,
            width: ['100%', '50%', '33.333333333%']
          }} mdxType="Box">
          <Flex sx={{
              alignItems: 'center',
              backgroundColor: 'surface',
              flex: '1 1 auto',
              flexDirection: 'column',
              justifyContent: 'center',
              p: 3,
              position: 'relative'
            }} mdxType="Flex">
            <Heading as="h4" variant="styles.h4" mdxType="Heading">
              Average word count
            </Heading>
            <Donut sx={{
                mx: 3,
                mb: 2
              }} value={wordCountAverage / wordCountTotal} mdxType="Donut" />
            <Box sx={{
                position: 'absolute'
              }} mdxType="Box">
              <Text sx={{
                  textAlign: 'center',
                  color: 'primary',
                  fontSize: '22px',
                  fontWeight: 'bold',
                  lineHeight: '1'
                }} mdxType="Text">
                {wordCountAverage}
              </Text>
              <Text sx={{
                  textAlign: 'center',
                  color: 'primary',
                  lineHeight: '1'
                }} mdxType="Text">Words</Text>
            </Box>
            <Text sx={{
                textAlign: 'center'
              }} mdxType="Text">{`Total words: ${wordCountTotal}`}</Text>
          </Flex>
        </Box>
        <Box sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            mb: 3,
            px: 2,
            width: ['100%', '50%', '33.333333333%']
          }} mdxType="Box">
          <Flex sx={{
              alignItems: 'center',
              backgroundColor: 'surface',
              flex: '1 1 auto',
              flexDirection: 'column',
              justifyContent: 'center',
              p: 3,
              position: 'relative'
            }} mdxType="Flex">
            <Heading as="h4" variant="styles.h4" mdxType="Heading">
              Average time to read
            </Heading>
            <Donut variant="styles.donut.secondary" sx={{
                mx: 3,
                mb: 2
              }} value={timeToReadAverage / timeToReadTotal} mdxType="Donut" />
            <Box sx={{
                position: 'absolute'
              }} mdxType="Box">
              <Text sx={{
                  textAlign: 'center',
                  color: 'secondary',
                  fontSize: '20px',
                  fontWeight: 'bold',
                  lineHeight: '1'
                }} mdxType="Text">
                {timeToReadAverage}
              </Text>
              <Text sx={{
                  textAlign: 'center',
                  color: 'secondary',
                  lineHeight: '1'
                }} mdxType="Text">Minute</Text>
            </Box>
            <Text sx={{
                textAlign: 'center'
              }} mdxType="Text">{`Total read time: ${timeToReadTotal} mins`}</Text>
          </Flex>
        </Box>
        <Box sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            mb: 3,
            px: 2,
            width: ['100%', '50%', '33.333333333%']
          }} mdxType="Box">
          <Flex sx={{
              alignItems: 'center',
              backgroundColor: 'surface',
              flex: '1 1 auto',
              flexDirection: 'column',
              justifyContent: 'center',
              p: 3,
              position: 'relative'
            }} mdxType="Flex">
            <Heading as="h4" variant="styles.h4" mdxType="Heading">
              Highest word count
            </Heading>
            <Donut variant="styles.donut.success" sx={{
                mx: 3,
                mb: 2
              }} value={wordCountHighest / wordCountTotal} mdxType="Donut" />
            <Box sx={{
                position: 'absolute'
              }} mdxType="Box">
              <Text sx={{
                  textAlign: 'center',
                  color: 'success',
                  fontSize: '20px',
                  fontWeight: 'bold',
                  lineHeight: '1'
                }} mdxType="Text">
                {wordCountHighest}
              </Text>
              <Text sx={{
                  textAlign: 'center',
                  color: 'success',
                  lineHeight: '1'
                }} mdxType="Text">Words</Text>
            </Box>
            <Text sx={{
                textAlign: 'center'
              }} mdxType="Text">{`Total words: ${wordCountTotal}`}</Text>
          </Flex>
        </Box>
      </Flex>;
      }}
    </SourceWords>
    <Divider mdxType="Divider" />
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<SourceWords>
  {(sourceWords) => {
    const currentYear = sourceWords.wordCountByMonth[sourceWords.wordCountByMonth.length - 1]
    return (
      <Box sx={{ backgroundColor: 'surface', p: 3 }}>
        <Flex>
          <Heading variant="styles.h4" sx={{ mr: 2 }}>
            {currentYear[0].year}
          </Heading>
          <Text>Word count by month</Text>
        </Flex>
        <Box sx={{ display: 'flex', flex: '1 1 auto', height: 300 }}>
          <Flex sx={{ flexWrap: 'wrap', width: '100%' }}>
            {currentYear.map((month, index) => {
              const { initial, words } = month
              return (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    pl: 1,
                    pr: 1,
                    width: \`\${100 / currentYear.length}%\`,
                  }}
                >
                  <Text
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    {\`x\${words}\`}
                  </Text>
                  <Box
                    sx={{
                      backgroundColor: 'primary',
                      height: \`\${words}%\`,
                      p: 1,
                    }}
                  />
                  <Text
                    sx={{
                      textTransform: 'uppercase',
                      textAlign: 'center',
                    }}
                  >
                    {initial}
                  </Text>
                </Box>
              )
            })}
          </Flex>
        </Box>
      </Box>
    )
  }}
</SourceWords>
`}</code></pre>
    <SourceWords mdxType="SourceWords">
  {sourceWords => {
        const currentYear = sourceWords.wordCountByMonth[sourceWords.wordCountByMonth.length - 1];
        return <Box sx={{
          backgroundColor: 'surface',
          p: 3
        }} mdxType="Box">
        <Flex mdxType="Flex">
          <Heading variant="styles.h4" sx={{
              mr: 2
            }} mdxType="Heading">
            {currentYear[0].year}
          </Heading>
          <Text mdxType="Text">Word count by month</Text>
        </Flex>
        <Box sx={{
            display: 'flex',
            flex: '1 1 auto',
            height: 300
          }} mdxType="Box">
          <Flex sx={{
              flexWrap: 'wrap',
              width: '100%'
            }} mdxType="Flex">
            {currentYear.map((month, index) => {
                const {
                  initial,
                  words
                } = month;
                return <Box key={index} sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  pl: 1,
                  pr: 1,
                  width: `${100 / currentYear.length}%`
                }} mdxType="Box">
                  <Text sx={{
                    textAlign: 'center'
                  }} mdxType="Text">
                    {`x${words}`}
                  </Text>
                  <Box sx={{
                    backgroundColor: 'primary',
                    height: `${words}%`,
                    p: 1
                  }} mdxType="Box" />
                  <Text sx={{
                    textTransform: 'uppercase',
                    textAlign: 'center'
                  }} mdxType="Text">
                    {initial}
                  </Text>
                </Box>;
              })}
          </Flex>
        </Box>
      </Box>;
      }}
    </SourceWords>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      